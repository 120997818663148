<template>
  <v-list-item
    v-if="group.items.length - group.shown.length"
    @click.stop="showMore"
  >
    <v-list-item-title>{{$tc('t.MoreItems', group.items.length - group.shown.length)}}</v-list-item-title>
  </v-list-item>
</template>
<script>
export default {
  methods: {
    showMore () {
      this.$emit('showMore', this.group)
      this.$triggerResize()
    }
  },
  props: {
    group: Object
  }
}
</script>
